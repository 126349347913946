<script>
import { bank_list } from "@/api/my";
export default {
    name:'Personal_BankCardInfo',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            bankbbool: true,
            bank: "",
            bank_num: "",
            bank_name: "",
            cardTypeName: "",
            background_pic: "",
            logo: "",

            header: {
                mainTitle: getWord(['bankcard', 'management']),
                mainTitle2: getWord('bankcard2'),
            },
            bankOutlets:null,
            bankCardAccountName:null,
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        search_bank() {
            bank_list().then(result => {
                if (result.data.code == "SUCCESS") {
                    if (!result.data.result.length == 0) {
                        this.bankbbool = false;
                        this.logo = result.data.result[0].logo;
                        this.background_pic = result.data.result[0].background_pic;
                        this.bank_name = result.data.result[0].bank_name;
                        this.bank_num = result.data.result[0].bank_num;
                        this.bank_num = this.bank_num.replace(/\s*/g, "");
                        this.bankOutlets=result.data.result[0].subbranch;
                        this.bankCardAccountName=result.data.result[0].pay_name
                        var _this = this;
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        }
    },
    mounted() {
        this.search_bank();
    }
};
</script>
<template>
    <section id="bank-card-info" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <Header
                :mainTitle="header.mainTitle2"
                :backButton=false
            />
            <div class="myposition"></div>
            <div class="main">
                <!-- <mt-header :title="getWord('bankcard2')" style="height:0.88rem;font-size: 0.35rem;">
                    <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
                </mt-header> -->
                <div v-if="bankbbool">
                    <div class="main_image"></div>
                    <span>{{ getWord('bankcard_tips') }}</span>
                    <router-link to="/my/my_bank/add_bank">
                        <mt-button class="main_button" type="primary">
                            <div class="jh"></div>{{ getWord('add_bankcard2') }}
                        </mt-button>
                    </router-link>
                </div>
                <div class="info" v-if="!bankbbool">
                    <img :src="background_pic" alt class="bank">
                    <img :src="logo" alt class="bank_logo">
                    <div class="bank_text1">{{bank_name}}</div>
                    <div class="bank_text2">{{cardTypeName}}</div>
                    <div class="bank_text3">{{bank_num}}</div>
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="info">
                <img :src="background_pic" alt class="bank">
                <img :src="logo" alt class="bank_logo">
                <div class="bank_text1">
                    {{bank_name}}
                    <span>{{ getWord('debit_card') }}</span>
                </div>
                <div class="bank_text2">{{cardTypeName}}</div>
                <div class="bank_text3">{{bank_num}}</div>
            </div>

            <div class="form-body">
                <div class="fieldset clearfix">
                    <span>{{ getWord(['bank', 'cardnumber']) }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="bank_num">
                </div>
                <div class="fieldset clearfix">
                    <span>{{ getWord('bank_name') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="bank_name" />
                </div>
                <div class="fieldset clearfix">
                    <span>{{ getWord('bank_branch_name') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="bankOutlets" />
                </div>
                <div class="fieldset clearfix">
                    <span>{{ getWord('real_name') }}</span>
                    <input @blur="_blured($event)" type="text" disabled="true" v-model="bankCardAccountName" />
                </div>
            </div>
        </template>
    </section>
</template>
<style scoped lang='scss' style="text/css">
#bank-card-info {

    &.template-3 {
        background-color: #eeeeee;
        height: 100%;

        .form-body {
            padding: 0 .25rem;
            background-color: #ffffff;

            .fieldset {
                padding: .2rem 0;
                border-top: 1px solid #d0d0d0;
                display: flex;
                align-items: center;

                &:first-child {
                    border-top: 0;
                }

                span {
                    flex-basis: 1.5rem;
                    font-size: .3rem;
                    color: #333333;
                }

                input {
                    border: 0;
                    box-sizing: border-box;
                    padding: 0 .25rem;
                    font-size: .3rem;
                    height: .48rem;
                    vertical-align: middle;
                    width: 100%;

                    &:disabled {
                        background-color:transparent;
                        -webkit-text-fill-color: #333333;
                        opacity: 1;
                    }

                    &:focus {
                        background-color: #FAFFBD;
                    }

                    &::placeholder {
                        color: #B0B0B0;
                    }
                }

                .iconfont {
                    font-size: .4rem;
                    text-align: center;
                    display: inline-block;
                    color: #B0B0B0;
                    vertical-align: middle;
                }
            }
        }

        .info {
            background-color: #ffffff;
            text-align: center;
            padding: .25rem 0;
            margin-bottom: .25rem;

            .bank {
                width: 7.1rem;
                border: none;
                margin: auto;
                border-radius: 0;
                height: auto;
            }

            .bank_text1 {

                span {
                    display: block;
                    font-size: .24rem;
                    text-align: left;
                }
            }
        }
    }

    .info {
        position:relative;       
    }
}
.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.main_image {
    float: left;
    height: 1.46rem;
    width: 1.45rem;
    background-image: url('/static/assets/images2/user/mybank/zwyhk.png');
    background-size: 100%;
    margin: 2.4rem 0 0 3rem;
}

.main span {
    float: left;
    width: 4rem;
    height: 0.25rem;
    font-size: 0.25rem;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    margin: 0.4rem 0 0 2.5rem;
}

.main_button {
    float: left;
    width: 3.98rem;
    height: 0.68rem;
    margin: 0.6rem 0 0 1.8rem;
}

.jh {
    float: left;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url('/static/assets/images2/user/mybank/jh.png');
    background-size: 100% 100%;
    margin-top: 0.1rem;
}

.bank {
    width: 7.1rem;
    height: 2.63rem;
    margin: 0 auto;
    border-radius: 0.12rem;
    margin: 0.2rem 0 0 0.2rem;
    border: 2px solid rgba(187, 187, 187, 1);
}

.bank_logo {
    float: left;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    top: 0.6rem;
    left: 0.6rem;
}

.bank_text1 {
    position: absolute;
    font-size: 0.3rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    top: 0.6rem;
    left: 1.5rem;
}

.bank_text2 {
    position: absolute;
    font-size: 0.24rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    top: 1rem;
    left: 1.5rem;
}

.bank_text3 {
    position: absolute;
    font-size: 0.36rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    top: 1.4rem;
    left: 1.5rem;
}
</style>