import { render, staticRenderFns } from "./no_bank.vue?vue&type=template&id=dd1a926c&scoped=true&"
import script from "./no_bank.vue?vue&type=script&lang=js&"
export * from "./no_bank.vue?vue&type=script&lang=js&"
import style0 from "./no_bank.vue?vue&type=style&index=0&id=dd1a926c&prod&scoped=true&lang=scss&style=text%2Fcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd1a926c",
  null
  
)

export default component.exports